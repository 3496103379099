nav{
   align-self: center;
}

.jumbotron{
   text-align: center;
   background-image: url(../images/stock.jpg);
   background-position: center;
   background-size: cover; 
   width:100%;
}

.big-link{
   color: #777;
   font-size: 1.5rem;
   padding-left: 5px;
}

.center{
   text-align:center;
}

.container{
   background-color:#fff;
   border-radius: 5px;
   padding:20px
}

.nav-container img{
   width:200px;
   margin-right:20px;
   float:left;
   position: relative;
}

.nav-container{
   min-height: 120px;
   display:flex;
}
.nav-item{
   padding:0px 10px
}

.footer{
   background-color:#111111;
   color:white;
}
.footer a{
   color:white;
}

.container-fluid{
   max-width: 1000px;
}

#mc_embed_signup{ clear:left; font:14px Helvetica,Arial,sans-serif; }
#mc_embed_signup label{
   font-weight: 400;
}
